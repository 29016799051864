



























































import { Component, Model } from "vue-property-decorator";

import { mixins } from 'vue-class-component';
import SharedMixin from '@/mixins/SharedMixin';

import { RoleModel, UserModel } from "@/services/iIdentityService";

@Component({
  components: { },
})
export default class PgaUserDetails extends mixins(SharedMixin)
{
  @Model("", { type: Object }) readonly Model!: UserModel;

  get fullName(): string
  {
    return this.Model?.FullName ?? "";
  }

  get email(): string
  {
    return this.Model?.Email ?? "";
  }

  get roles(): Array<RoleModel>
  {
    return this.Model?.Roles ?? new Array<RoleModel>();
  }

  get tenantName(): string
  {
    return this.Model?.Tenant?.Name ?? "";
  }

  get createDate(): string
  {
    const date: Date = new Date(this.Model?.CreateDate) ?? new Date();
		return date.toDateString();
  }
}
