













import { Component } from "vue-property-decorator";

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import { UserModel } from '@/services/iIdentityService';
import { UsersModule } from "./store";

import PgaUsersTableIndex from "./Components/PgaUsersTableIndex.vue";

@Component({
  components: {
    PgaUsersTableIndex,
  },
})
export default class UsersPageIndex extends mixins(SharedMixin)
{
  private isLoading = true;

  get UsersList(): Array<UserModel> {
    return UsersModule.UsersTable.Users;
  }

  async mounted(): Promise<void> {
    this.isLoading = true;
    await UsersModule.GetAllUsers();
    this.isLoading = false;
  }
}
