































































import { Component, Model } from "vue-property-decorator";
import { UsersModule } from "../store";

import { mixins } from "vue-class-component";
import SharedMixin from '@/mixins/SharedMixin';

import { EditUserCommand, RoleModel } from "@/services/iIdentityService";

@Component({
  components: { },
})
export default class PageUserEdit extends mixins(SharedMixin)
{
  @Model("", { type: Object }) readonly Model!: EditUserCommand;

  get Errors(): Map<string, Array<string>>
  {
    return UsersModule.UserEdit?.Errors ?? new Map<string, Array<string>>();
  }

  get fullName(): string
  {
    return this.Model?.FullName ?? "";
  }
  set fullName(fullName: string)
  {
    UsersModule.SET_USER_EDIT_FULLNAME(fullName);
  }

  get email(): string
  {
    return this.Model?.Email ?? "";
  }
  set email(email: string)
  {
    UsersModule.SET_USER_EDIT_EMAIL(email);
  }

  get rolesSelected(): Array<RoleModel>
  {
    return UsersModule.UserEdit.ViewModel?.Roles ?? new Array<RoleModel>();
  }
  set rolesSelected(value: Array<RoleModel>)
  {
    UsersModule.SET_USER_EDIT_ROLES(value.map(r => r.Id));
  }

  get tenantName(): string
  {
    return UsersModule.UserEdit.ViewModel?.Tenant?.Name ?? "";
  }

  get createDate(): string
  {
    const dt = new Date(UsersModule.UserEdit.ViewModel?.CreateDate as Date) ?? new Date();
    return dt.toDateString();
  }

  get allRoles(): Array<RoleModel>
  {
    return UsersModule.RolesDropDown.Roles;
  }
}
