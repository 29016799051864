













import { Component } from "vue-property-decorator";
import { Guid } from "guid-typescript";

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import { UserModel } from '@/services/iIdentityService';
import { UsersModule } from "./store";

import PgaUserDetails from "./Components/PgaUserDetails.vue";

@Component({
  components: {
    PgaUserDetails
  },
})
export default class UsersPageDetails extends mixins(SharedMixin)
{
  get UserDetails(): UserModel
  {
    return UsersModule.UserDetails;
  }

  Edit(): void
  {
    const id = this.$route.params.id;
    this.$router.push({ name: "useredit", params: { id } });
  }

  async mounted(): Promise<void>
  {    
    const id = this.$route.params.id;
    await UsersModule.GetUserDetails(Guid.parse(id));
  }
}
