


















import { Component, Prop } from "vue-property-decorator";
import { UserModel } from "@/services/iIdentityService";

import { mixins } from 'vue-class-component';
import SharedMixin from '@/mixins/SharedMixin';

@Component({})
export default class PgaUsersTableIndex extends mixins(SharedMixin)
{
  @Prop({ default: function() { return new Array<UserModel>() } }) readonly UsersList!: Array<UserModel>;
  @Prop({ default: true }) readonly isLoading!: boolean;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private headers: Array<any> = [
    {
      text: "Id",
      align: " d-none",
      class: "hidden",
      sortable: true,
      value: "Id",
    },
    {
      text: "Full Name",
      align: "start",
      sortable: true,
      value: "FullName",
      keySearch: "fn",
    },
    {
      text: "Email",
      align: "start",
      sortable: true,
      value: "Email",
      keySearch: "em",
    },
    {
      text: "Roles",
      align: "start",
      sortable: false,
      value: "Roles",
      keySearch: "ro",
    },
    {
      text: "Tenant",
      align: "start",
      sortable: true,
      value: "Tenant.Name",
      keySearch: "te",
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  rowClicked(item: any): void
  {
    const id = item.Id;
    this.$router.push({ name: "userdetails", params: { id } });
  }
}
