






















































import { Component, Model } from "vue-property-decorator";
import { UsersModule } from "../store";

import { mixins } from "vue-class-component";
import SharedMixin from '@/mixins/SharedMixin';

import { CreateUserCommand, RoleModel } from "@/services/iIdentityService";
import { SIPUserModel } from "@/services/iResourceService";

@Component({
  components: { },
})
export default class PgaUserCreate extends mixins(SharedMixin)
{
  @Model("", { type: Object }) readonly Model!: CreateUserCommand;

  // Simple Properties
  get fullName(): string
  {
    return this.Model?.FullName ?? "";
  }
  set fullName(fullname: string)
  {
    UsersModule.SET_USER_CREATE_FULLNAME(fullname);
  }

  get email(): string
  {
    return this.Model?.Email ?? "";
  }
  set email(email: string)
  {
    UsersModule.SET_USER_CREATE_EMAIL(email);
  }

  get rolesSelected() : Array<RoleModel>
  {
    return new Array<RoleModel>();
  }
  set rolesSelected(value: Array<RoleModel>)
  {
    UsersModule.SET_USER_CREATE_ROLES(value.map(r => r.Id));
  }

  get sipUserSelected() : SIPUserModel | null
  {
    return null;
  }
  set sipUserSelected(user: SIPUserModel | null)
  {
    UsersModule.SET_USER_CREATE_SIPUSER(user?.CodPers as number ?? -1);
  }

  // Roles
  get allRoles(): Array<RoleModel>
  {
    return UsersModule.RolesDropDown.Roles;
  }

  // SIP USers
  get allSIPUsers(): Array<SIPUserModel>
  {
    return UsersModule.SIPUserDropDown;
  }

  get Errors(): Map<string, Array<string>>
  {
    return UsersModule.UserCreate?.Errors ?? new Map<string, Array<string>>();
  }

  getNameEmail(item : SIPUserModel) : string
  {
    return `${item.NomePers} ${item.CognPers} - ${item.EmailPers}` 
  }
}
