






























import { Component } from "vue-property-decorator";
import { Guid } from "guid-typescript";

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import { EditUserResponse } from "@/services/iIdentityService";
import { EditUserModel, UsersModule } from "./store";
import PgaUserEdit from "./Components/PgaUserEdit.vue";

@Component({
  components: {
    PgaUserEdit
  },
})
export default class UsersPageEdit extends mixins(SharedMixin)
{
  private showDialog = false;
	private title = "";
  private message = "";
  
  get fullName(): string
  {
    return this.UserEdit.ViewModel?.FullName ?? ''
  }

  get UserEdit(): EditUserModel
  {
    return UsersModule.UserEdit;
  }

  async Edit() : Promise<void>
	{
		try
		{
			await UsersModule.EditUser();
			this.$router.go(-1);
		}
		// eslint-disable-next-line no-empty
		catch(error) { }
  }
  
  ShowDialogDelete(): void
	{
		this.title = "Deleting User";
		this.message = `Are you sure you want to delete the User '${this.UserEdit.ViewModel?.FullName}'?`;
		this.showDialog = true;
	}

	HideDialog(): void
	{
		this.showDialog = false;
		this.title = "";
		this.message = "";
	}

  async Delete(): Promise<void>
	{
		if (this.UserEdit.ViewModel)
			await UsersModule.DeleteUser(this.UserEdit.ViewModel.Id);

		this.showDialog = false;
		this.title = "";
		this.message = "";
		this.$router.push({ path: '/users' });
	}

  cancel(): void
  {
    this.$router.go(-1);
  }

  async mounted(): Promise<void>
  {
    const id = this.$route.params.id;
    await UsersModule.GetUserToEdit(Guid.parse(id));
    await UsersModule.GetRolesDropDown();
  }

  beforeDestroy() : void
	{
		UsersModule.UserEdit.Errors = new Map<string, Array<string>>();
	}
}
