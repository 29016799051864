















import { Component } from "vue-property-decorator";
import { UsersModule, CreateUserModel } from "./store";
import { CreateUserResponse } from "@/services/iIdentityService";

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import PgaUserCreate from "./Components/PgaUserCreate.vue";

@Component({
  components: {
    PgaUserCreate
  },
})
export default class UsersPageCreate extends mixins(SharedMixin)
{
  get UserCreate(): CreateUserModel
  {
    return UsersModule.UserCreate;
  }
  
  async Create() : Promise<void>
  {
    try 
    {
      const response: CreateUserResponse = await UsersModule.CreateUser();
      const id: string = response.Id.toString();
      this.$router.push({ name: 'userdetails', params: { id }});
    }
    // eslint-disable-next-line no-empty
    catch(error) { }
	}

  cancel(): void
  {
    this.$router.go(-1);
  }
  
  async mounted(): Promise<void>
  {
    UsersModule.InitUserCreate();
    await UsersModule.GetRolesDropDown();
    await UsersModule.GetSIPUsersDropDown();
  }

  beforeDestroy() : void
	{
		UsersModule.DropUserCreate();
		UsersModule.UserCreate.Errors = new Map<string, Array<string>>();
	} 
}
