import { VuexModule, Module, Mutation, getModule, Action } from 'vuex-module-decorators';
import store from '@/infrastructure/store';
import { Guid } from 'guid-typescript';

import IIdentityService from '@/services/iIdentityService';
import IdentityService from '@/services/IdentityService';
import pgaDiContainer from '@/App.container';

import IResourceService, { SIPUserModel } from '@/services/iResourceService';
import ResourceService from '@/services/ResourceService';

import { UserModel, RoleModel, ListRoles, ListUsers,
    CreateUserCommand, EditUserCommand, DeleteUserCommand,
    CreateUserResponse, EditUserResponse, DeleteUserResponse } from '@/services/iIdentityService';
import { SharedModule } from '@/feature/Shared/store';

export interface UsersState
{
    UsersTable: ListUsers;
    UserDetails: UserModel | null;
    UserCreate: CreateUserModel;
    UserEdit: EditUserModel;
    UserDelete: DeleteUserModel;

    RolesDropDown: ListRoles;
    SIPUserDropDown: Array<SIPUserModel>;
}

export interface CreateUserModel
{
    Command: CreateUserCommand | null;
    Response: CreateUserResponse | null;
    Errors: Map<string, Array<string>>;
}

export interface EditUserModel
{
    ViewModel: UserModel | null;
    Command: EditUserCommand | null;
    Response: EditUserResponse | null;
    Errors: Map<string, Array<string>>;
}

export interface DeleteUserModel
{
    Id: Guid;
    Response: DeleteUserResponse | null;
}

@Module({ namespaced: true, dynamic: true, store, name: 'UsersModule', })
class UsersStore extends VuexModule implements UsersState
{
    _identityService: IIdentityService = pgaDiContainer.get<IIdentityService>(IdentityService);
    _resourceService: IResourceService = pgaDiContainer.get<IResourceService>(ResourceService);

    public UsersTable: ListUsers = 
    {
        Users: new Array<UserModel>(),
    };

    public UserDetails: UserModel = 
    {
        Id: Guid.createEmpty(),
        FullName: '',
        Email: '',
        Roles: new Array<RoleModel>(),
        Tenant: 
        {
            Id: '',
            Name: '',
        },
        SIPCode: -1,
        CreateDate: new Date(),
    };

    public UserCreate: CreateUserModel = 
    {
        Command: null,
        Response: null,
        Errors: new Map<string, Array<string>>(),
    };

    public UserEdit: EditUserModel = 
    {
        ViewModel: null,
        Command: null,
        Response: null,
        Errors: new Map<string, Array<string>>(),
    };

    public UserDelete: DeleteUserModel =
    {
        Id: Guid.createEmpty(),
        Response: null,
    }
    

    public RolesDropDown: ListRoles =
    {
        Roles: new Array<RoleModel>(),
    }
    public SIPUserDropDown: Array<SIPUserModel> = new Array<SIPUserModel>();

    // DropDown Roles
    @Action({ rawError: true })
    public async GetRolesDropDown()
    {
        const roles: ListRoles = await this._identityService.ListRoles();
        this.SET_ROLES_DROPDOWN(roles);
    }
    @Mutation
    public SET_ROLES_DROPDOWN(roles: ListRoles)
    {
        this.RolesDropDown = roles;
    }

    // DropDown SIP Users
    @Action({ rawError: true })
    public async GetSIPUsersDropDown()
    {
        const sipUsers: Array<SIPUserModel> = (await this._resourceService.GetAllSIPUsers()).Users;
        this.SET_SIPUSERS_DROPDOWN(sipUsers);
    }
    @Mutation
    public SET_SIPUSERS_DROPDOWN(sipUsers: Array<SIPUserModel>)
    {
        this.SIPUserDropDown = sipUsers;
    }


    // List Users
    @Action({ rawError: true })
    public async GetAllUsers(): Promise<void> 
    {
        const users: ListUsers = await this._identityService.ListUsers();
        this.SET_USERS(users);
    }
    @Mutation
    public SET_USERS(users: ListUsers)
    {
        this.UsersTable = users;
    }

    // Details User
    @Action({ rawError: true })
    public async GetUserDetails(id: Guid)
    {
        SharedModule.SET_ISLOADING(true);
        const user: UserModel = await this._identityService.GetUser(id);
        this.SET_USER_DETAILS(user);
        SharedModule.SET_ISLOADING(false);
    }
    @Action({ rawError: true })
    public DropUserDetails()
    {
        const resetUserDetails: UserModel =
        {
            Id: Guid.createEmpty(),
            FullName: '',
            Email: '',
            Roles: new Array<RoleModel>(),
            Tenant: 
            {
                Id: '',
                Name: '',
            },
            SIPCode: -1,
            CreateDate: new Date(),
        };
        this.SET_USER_DETAILS(resetUserDetails);
    }
    @Mutation
    public SET_USER_DETAILS(user: UserModel)
    {
        this.UserDetails = user;
    }

    // Create User
    @Action({ rawError: true })
    public async CreateUser()
    {
        if (!this.UserCreate)
        {
            Promise.reject("User creation not initialized");
        }
        
        try
        {
            let response: CreateUserResponse = { Id: Guid.createEmpty(), };
            SharedModule.SET_ISLOADING(true);
            if (this.UserCreate.Command)
                response = await this._identityService.CreateUser(this.UserCreate.Command);
            SharedModule.SET_ISLOADING(true);
            return response;
        }
        catch (error)
        {            
            SharedModule.SET_ISLOADING(false);
            const errs = parseErrors(error);
            this.SET_USER_CREATE_ERRORS(errs);

            throw error;
        }
    }
    @Action({ rawError: true })
    public InitUserCreate()
    {
        const command: CreateUserCommand =
        {
            FullName: '',
            Email: '',
            SIPCode: -1,
            Roles: new Array<Guid>(),
        }

        this.SET_USER_CREATE(command);
    }
    @Action({ rawError: true })
    public async DropUserCreate()
    {
        this.SET_USER_CREATE(null);
    }
    @Mutation
    public SET_USER_CREATE(command: CreateUserCommand | null)
    {
        this.UserCreate.Command = command;
    }
    @Mutation
    public SET_USER_CREATE_FULLNAME(fullName: string)
    {
        if(this.UserCreate.Command)
            this.UserCreate.Command.FullName = fullName;
    }
    @Mutation
    public SET_USER_CREATE_EMAIL(email: string)
    {
        if(this.UserCreate.Command)
            this.UserCreate.Command.Email = email;
    }
    @Mutation
    public SET_USER_CREATE_ROLES(roleIds: Array<Guid>)
    {
        if(this.UserCreate.Command)
            this.UserCreate.Command.Roles = roleIds;
    }
    @Mutation
    public SET_USER_CREATE_SIPUSER(userCode: number)
    {
        if(this.UserCreate.Command)
            this.UserCreate.Command.SIPCode = userCode;
    }
    @Mutation
    public async SET_USER_CREATE_ERRORS(errors: Map<string, Array<string>>)
    {
        this.UserCreate.Errors = errors;
    }

    // Edit User
    @Action({ rawError: true })
    public async EditUser() : Promise<EditUserResponse>
    {
        try
        {
            let response: EditUserResponse = { Id: Guid.createEmpty(), };
            SharedModule.SET_ISLOADING(true);
            if(this.UserEdit.Command)
                response = await this._identityService.EditUser(this.UserEdit.Command);
            SharedModule.SET_ISLOADING(false);

            return response;
        }
        catch (error)
        {
            SharedModule.SET_ISLOADING(false);
            const errs = parseErrors(error);
            this.SET_USER_EDIT_ERRORS(errs);

            throw error;
        }
    }
    @Action({ rawError: true })
    public async GetUserToEdit(id: Guid)
    {
        SharedModule.SET_ISLOADING(true);

        this.SET_USER_EDIT_COMMAND(null);
        this.SET_USER_EDIT_VIEWMODEL(null);

        const user: UserModel = await this._identityService.GetUser(id);
        
        this.SET_USER_EDIT_VIEWMODEL(user);
        this.InitUserEdit();

        this.SET_USER_EDIT_ID(user.Id);
        this.SET_USER_EDIT_FULLNAME(user.FullName);
        this.SET_USER_EDIT_EMAIL(user.Email);
        this.SET_USER_EDIT_ROLES(user.Roles.map(p => p.Id));
        
        SharedModule.SET_ISLOADING(false);
    }
    @Action({ rawError: true })
    public InitUserEdit()
    {
        const command: EditUserCommand =
        {
            Id: Guid.createEmpty(),
            FullName: '',
            Email: '',
            Roles: new Array<Guid>(),
        }

        this.SET_USER_EDIT_COMMAND(command);
    }
    @Mutation
    public SET_USER_EDIT_VIEWMODEL(user: UserModel | null)
    {
        this.UserEdit.ViewModel = user;
    }
    @Mutation
    public SET_USER_EDIT_COMMAND(user: EditUserCommand | null)
    {
        this.UserEdit.Command = user;
    }
    @Mutation
    public SET_USER_EDIT_ID(id: Guid)
    {
        if (this.UserEdit.Command)
            this.UserEdit.Command.Id = id;
    }
    @Mutation
    public SET_USER_EDIT_FULLNAME(fullName: string)
    {
        if (this.UserEdit.Command)
            this.UserEdit.Command.FullName = fullName;
    }
    @Mutation
    public SET_USER_EDIT_EMAIL(email: string)
    {
        if (this.UserEdit.Command)
            this.UserEdit.Command.Email = email;
    }
    @Mutation
    public SET_USER_EDIT_ROLES(roleIds: Array<Guid>) {
        if (this.UserEdit.Command)
            this.UserEdit.Command.Roles = roleIds;
    }
    @Mutation
    public async SET_USER_EDIT_ERRORS(errors: Map<string, Array<string>>)
    {
        this.UserEdit.Errors = errors;
    }
    

    // Delete User
    @Action({ rawError: true })
    public async DeleteUser(id: Guid): Promise<DeleteUserResponse>
    {
        try {
            let response: DeleteUserResponse = { Id: Guid.createEmpty() };
            SharedModule.SET_ISLOADING(true);
            this.SET_USER_DELETE(id);

            if (this.UserDelete)
                response = await this._identityService.DeleteUser(this.UserDelete.Id);
            
            SharedModule.SET_ISLOADING(false);
            return response;
        }
        catch (error) {
            SharedModule.SET_ISLOADING(false);

            throw error;
        }
    }
    @Mutation
    public SET_USER_DELETE(id: Guid)
    {
        this.UserDelete.Id = id;
    }
}

// TODO: @fventurini I'd like to move that as a private member of the store
// but can't afford to do it!
function parseErrors(error: any): Map<string, Array<string>> {
    const errs = new Map<string, Array<string>>();
    for (const [key, value] of Object.entries(error.response.data)) {
        const messages = value as Array<string>;
        errs.set(key, messages);
    }
    return errs;
}

export const UsersModule = getModule(UsersStore);
